<template>
  <b-modal
    id="delete-user-modal"
    :title="`Delete ${user.username}`"
    size="lg"
    :ok-title="loadingDeleteAction ? 'Loading...' : 'Delete user'"
    :ok-disabled="loadingUserDetails || loadingDeleteAction"
    ok-variant="danger"
    cancel-title="Cancel"
    cancel-variant="flat-secondary"
    lazy
    @show="onShow"
    @ok.prevent="onSubmit"
  >
    <b-alert show variant="danger">
      <div class="alert-body d-inline-flex justify-content-between">
        <div class="mr-1">
          <feather-icon
            icon="AlertCircleIcon"
            size="30"
          />
        </div>
        <div>
          <p>
            This action cannot be undone.
          </p>
        </div>
      </div>
    </b-alert>
    <p>
      If deleted, this user will no longer be able to access Kompozition.
    </p>
    <p>
      Their contributions will remain unaffected
      however their name will no longer be displayed and properties such as 'created_by' will
      display their ID instead, such as <span class="text-primary text-nowrap select-all">{{ user.id }}</span>
    </p>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import coreService from '@/libs/api-services/core-service'
import { BAlert } from 'bootstrap-vue'

export default {
  name: 'DeleteUserModal',
  components: { BAlert },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const loadingDeleteAction = ref(false)
    const loadingUserDetails = ref(false)
    const user = ref({
      username: '',
      email: '',
      firstName: '',
      lastName: '',
    })

    const onShow = () => {
      loadingUserDetails.value = true
      coreService.userManagementApi
        .getUser(props.userId)
        .then(response => { user.value = response })
        .finally(() => {
          loadingUserDetails.value = false
        })
    }

    const onSubmit = async () => {
      // Can only delete users that are already deactivated
      if (user.value.enabled === false) {
        loadingDeleteAction.value = true
        coreService.userManagementApi.deleteUser(props.userId)
          .then(response => {
            context.emit('user-deleted')
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'User Deleted',
                text: `${user.value.username}`,
                icon: 'UserXIcon',
                variant: 'success',
              },
            })
            context.root.$bvModal.hide('delete-user-modal')
          })
          .catch(error => {
            context.root.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to delete user',
                text: `${user.value.username}`,
                icon: 'UserXIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => { loadingDeleteAction.value = false })
      } else {
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cannot delete active users',
            text: 'Deactivate the user first before deletion',
            icon: 'UserXIcon',
            variant: 'danger',
          },
        })
      }
    }

    return {
      user,
      loadingUserDetails,
      loadingDeleteAction,
      onShow,
      onSubmit,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
