<template>
  <b-modal
    id="create-user-modal"
    title="Create User"
    size="lg"
    :ok-title="loading ? 'Loading...' : 'Create User'"
    :ok-disabled="loading"
    ok-variant="success"
    cancel-title="Discard"
    cancel-variant="outline-secondary"
    lazy
    no-fade
    no-close-on-backdrop
    @show="onShow"
    @hidden="onClose"
    @ok.prevent="onSubmit"
  >
    <b-form class="mt-0">
      <validation-observer ref="userValidation">
          <b-form-group
            label="Username"
            label-for="username"
          >
            <validation-provider
              #default="{ errors }"
              name="Username"
              rules="required|min-length-5|max-length-254"
            >
              <b-form-input
                v-model="user.username"
                name="username"
                placeholder="taylor.smith"
                type="text"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider #default="{ errors }" name="Email" rules="required|max-length-254">
              <b-form-input
                v-model="user.email"
                name="email"
                placeholder="taylor.smith@acme.com"
                type="email"
                :formatter="value => value.toLowerCase()"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="First name"
            label-for="first-name"
          >
            <validation-provider #default="{ errors }" name="First Name" rules="required|max-length-254">
              <b-form-input
                v-model.trim="user.firstName"
                name="firstName"
                placeholder="Taylor"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Last name"
            label-for="last-name"
          >
            <validation-provider #default="{ errors }" name="Last Name" rules="required|max-length-254">
              <b-form-input
                v-model.trim="user.lastName"
                name="lastName"
                placeholder="Smith"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Job title"
            label-for="account-jobTitle"
          >
            <validation-provider #default="{ errors }" name="Job Title" rules="max-length-254">
              <b-form-input
                v-model="user.jobTitle"
                name="jobTitle"
                placeholder="Astronaut"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
      </validation-observer>

      <hr class="mt-2">

      <div class="mb-1">
        <small class="text-muted">Password Policy:</small>
        <span class="font-weight-bold text-info">
          {{ passwordPolicy.join(', ') }}
        </span>
      </div>

      <validation-observer ref="passwordValidation">
        <b-form-group
          label="Password"
          label-for="new-password"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            rules="required|password:@confirm"
          >
            <b-form-input
              id="new-password"
              v-model="user.password"
              name="new-password"
              autocomplete="new-password"
              type="password"
              autocapitalize="off"
              autocorrect="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Confirm Password"
          label-for="confirm-password"
        >
          <validation-provider
            v-slot="{ errors }"
            name="confirm"
            rules="required"
          >
            <b-form-input
              id="confirm-password"
              v-model="user.password_confirmation"
              name="confirm-password"
              autocomplete="new-password"
              type="password"
              autocapitalize="off"
              autocorrect="off"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>

      <b-form-group label="Additional options">
        <b-form-checkbox
          id="send-verification-email-checkbox"
          v-model="user.sendVerificationEmail"
          class="ml-50"
          name="send-verification-email-checkbox"
        >
          Send verification email <span class="text-muted font-small-2">(recommended)</span>
        </b-form-checkbox>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { ref } from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'CreateUserModal',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  setup(props, context) {
    const user = ref({
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      jobTitle: '',
      password: '',
      password_confirmation: '',
      sendVerificationEmail: true,
    })
    const passwordPolicy = ref([])
    const userValidation = ref(null)
    const passwordValidation = ref(null)

    const onShow = async () => {
      passwordPolicy.value = await coreService.adminCliApi.getPasswordPolicy()
    }

    const loading = ref(false)
    const onSubmit = async () => {
      const userValid = await userValidation.value.validate()
      const passwordValid = await passwordValidation.value.validate()

      if (userValid && passwordValid) {
        const userPayload = {
          username: user.value.username,
          email: user.value.email,
          first_name: user.value.firstName,
          last_name: user.value.lastName,
          job_title: user.value.jobTitle || '',
          password: user.value.password,
          enabled: true,
          send_email_verification: user.value.sendVerificationEmail,
        }

        loading.value = true
        coreService.usersManagementApi.createUser(userPayload)
          .then(response => {
            context.emit('user-created')
            context.root.$bvModal.hide('create-user-modal')
          })
          .finally(() => { loading.value = false })
      }
    }
    const onClose = () => {
      user.value = {
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        password: '',
        password_confirmation: '',
        sendVerificationEmail: true,
      }
    }

    // Form validation extensions
    extend('min-length-5', value => {
      if (value.length >= 5) {
        return true
      }
      return '{_field_} must be at least 5 characters'
    })
    extend('password', {
      params: ['target'],
      validate(value, { target }) {
        return value === target
      },
      message: 'Password confirmation does not match',
    })
    extend('max-length-254', value => {
      if (value.length <= 254) {
        return true
      }
      return '{_field_} must be less than 254 characters'
    })

    return {
      user,
      userValidation,
      passwordValidation,
      passwordPolicy,
      loading,
      onShow,
      onSubmit,
      onClose,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
