var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"create-user-modal","title":"Create User","size":"lg","ok-title":_vm.loading ? 'Loading...' : 'Create User',"ok-disabled":_vm.loading,"ok-variant":"success","cancel-title":"Discard","cancel-variant":"outline-secondary","lazy":"","no-fade":"","no-close-on-backdrop":""},on:{"show":_vm.onShow,"hidden":_vm.onClose,"ok":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form',{staticClass:"mt-0"},[_c('validation-observer',{ref:"userValidation"},[_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|min-length-5|max-length-254"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"username","placeholder":"taylor.smith","type":"text","required":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|max-length-254"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"email","placeholder":"taylor.smith@acme.com","type":"email","formatter":function (value) { return value.toLowerCase(); },"required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"First name","label-for":"first-name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required|max-length-254"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"firstName","placeholder":"Taylor"},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last name","label-for":"last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|max-length-254"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"lastName","placeholder":"Smith"},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Job title","label-for":"account-jobTitle"}},[_c('validation-provider',{attrs:{"name":"Job Title","rules":"max-length-254"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"jobTitle","placeholder":"Astronaut"},model:{value:(_vm.user.jobTitle),callback:function ($$v) {_vm.$set(_vm.user, "jobTitle", $$v)},expression:"user.jobTitle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('hr',{staticClass:"mt-2"}),_c('div',{staticClass:"mb-1"},[_c('small',{staticClass:"text-muted"},[_vm._v("Password Policy:")]),_c('span',{staticClass:"font-weight-bold text-info"},[_vm._v(" "+_vm._s(_vm.passwordPolicy.join(', '))+" ")])]),_c('validation-observer',{ref:"passwordValidation"},[_c('b-form-group',{attrs:{"label":"Password","label-for":"new-password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|password:@confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"new-password","name":"new-password","autocomplete":"new-password","type":"password","autocapitalize":"off","autocorrect":"off"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"confirm-password"}},[_c('validation-provider',{attrs:{"name":"confirm","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirm-password","name":"confirm-password","autocomplete":"new-password","type":"password","autocapitalize":"off","autocorrect":"off"},model:{value:(_vm.user.password_confirmation),callback:function ($$v) {_vm.$set(_vm.user, "password_confirmation", $$v)},expression:"user.password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-form-group',{attrs:{"label":"Additional options"}},[_c('b-form-checkbox',{staticClass:"ml-50",attrs:{"id":"send-verification-email-checkbox","name":"send-verification-email-checkbox"},model:{value:(_vm.user.sendVerificationEmail),callback:function ($$v) {_vm.$set(_vm.user, "sendVerificationEmail", $$v)},expression:"user.sendVerificationEmail"}},[_vm._v(" Send verification email "),_c('span',{staticClass:"text-muted font-small-2"},[_vm._v("(recommended)")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }