<template>
  <div id="UserManagement">
    <div class="w-100 d-inline-flex justify-content-between align-items-center mb-1">
      <h2>User Management</h2>
      <b-button-group>
        <!--TODO Implement user report export-->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          title="Export user report to CSV (Coming soon)"
          variant="outline-primary"
          size="sm"
          :disabled="true"
          @click="() => { console.log('User export is not yet implemented.') }"
        >
          <feather-icon icon="DownloadIcon" />
          Export User Report
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          title="Refresh users"
          variant="outline-info"
          size="sm"
          @click="fetchUsers"
        >
          <feather-icon icon="RefreshCwIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          title="Create new user"
          variant="outline-success"
          size="sm"
          data-cy="btn-user-create"
          @click="$bvModal.show('create-user-modal')"
        >
          <feather-icon icon="UserPlusIcon" class="mr-25" />
          Create User
        </b-button>
      </b-button-group>
    </div>

    <div v-if="loading">
      <div class="w-100 d-inline-flex justify-content-center mt-4">
        <div class="d-flex flex-column align-items-center">
          <h5 class="text-warning mb-2">
            <atom-spinner class="animate-pulse" />
          </h5>
          <h5>
            Loading Users...
          </h5>
        </div>
      </div>
    </div>
    <div v-else class="w-100 d-flex justify-content-around">
      <b-card no-body class="w-100">
        <b-card-body>
          <div class="d-flex justify-content-between flex-wrap">

            <!-- Sorting  -->
            <b-form-group
              label="Sort"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="1"
              label-for="sortBySelect"
              label-class="mt-50 mr-50 pl-0"
              class="mb-0 text-nowrap"
              style="min-width: 20rem"
            >
              <b-input-group
                size="sm"
              >
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      none
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Asc
                  </option>
                  <option :value="true">
                    Desc
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>
            <!-- ./Sorting  -->

            <!-- Search -->
            <b-form-group
              label="Search"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              label-class="mt-50"
              class="mb-0"
              style="width: 30rem"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder=""
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- ./Search -->
          </div>
        </b-card-body>

        <b-table
          responsive
          striped
          show-empty
          class="position-relative"
          :items="allUsers"
          :fields="userTableFields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(avatar)="{ item }">
            <pre>{{ item.id }}</pre>
          </template>

          <template #cell(enabled)="{ item }">
            <b-badge
              class="mr-25"
              :title="item.enabled ? '' : 'Deactivated users cannot login to the platform'"
              :variant="item.enabled ? 'info' : 'secondary'"
            >
              {{ item.enabled ? 'Active' : 'Deactivated' }}
            </b-badge>
          </template>

          <template #cell(username)="{ item }">
            <router-link
              :to="{ name: 'manage_user', params: { userId: item.id } }"
              class="custom-link"
            >
              <span v-if="item.enabled" class="select-all">
                {{ item.username }}
              </span>
              <del v-else class="select-all">
                {{ item.username }}
              </del>
            </router-link>
          </template>

          <template #cell(firstName)="{ item }">
            <span v-if="item.enabled">
              {{ item.firstName }} {{ item.lastName }}
            </span>
            <del v-else>
              {{ item.firstName }} {{ item.lastName }}
            </del>
          </template>

          <template #cell(email)="{ item }">
            <span v-if="item.enabled" class="select-all">
              {{ item.email }}
            </span>
            <del v-else class="select-all">
              {{ item.email }}
            </del>
          </template>

          <template #cell(jobTitle)="{ item }">
            <span v-if="item.enabled">
              {{ item.jobTitle }}
            </span>
            <del v-else>
              {{ item.jobTitle }}
            </del>
          </template>

          <template #cell(requiredActions)="{ item }">
            <b-badge
              v-for="action in item.requiredActions"
              :key="action"
              :title="resolveActionDescription(action)"
              class="mr-25"
              variant="info"
            >
              {{ action }}
            </b-badge>
          </template>

          <template #cell(actions)="{ item }">
            <div class="d-inline-flex">
              <b-button
                title="Show details"
                variant="flat-primary"
                class="py-0 btn-icon"
                :data-user-id="item.id"
                @click="$router.push({ name: 'manage_user', params: { userId: item.id } })"
              >
                <feather-icon
                  icon="EditIcon"
                  class="align-text-top"
                />
              </b-button>
              <b-button
                v-if="item.enabled === true && item.id !== $store.state.auth.id"
                title="Deactivate user"
                variant="flat-secondary"
                class="py-0 btn-icon"
                :data-user-id="item.id"
                @click="deactivateUser(item.id)"
              >
                <feather-icon
                  icon="UserMinusIcon"
                  class="align-text-top"
                />
              </b-button>
              <b-button
                v-else-if="item.id !== $store.state.auth.id"
                title="Activate user"
                variant="flat-success"
                class="py-0 btn-icon"
                :data-user-id="item.id"
                @click="activateUser(item.id)"
              >
                <feather-icon
                  icon="UserCheckIcon"
                  class="align-text-top"
                />
              </b-button>
              <b-button
                v-if="item.enabled === false && item.id !== $store.state.auth.id"
                title="Permanently delete user"
                variant="flat-danger"
                class="py-0 btn-icon"
                :data-user-id="item.id"
                @click="openDeleteUserModal(item.id)"
              >
                <feather-icon
                  icon="UserXIcon"
                  class="align-text-top"
                />
              </b-button>
            </div>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- Page length -->
          <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-class="mt-50"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
          <!-- ./Page length -->

          <!-- Pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <!-- ./Pagination -->
        </b-card-body>
      </b-card>
    </div>

    <div id="BV_Modals" class="d-none">
      <create-user-modal @user-created="fetchUsers" />
      <delete-user-modal :user-id="selectedUserId" @user-deleted="fetchUsers" />
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  computed, nextTick, onUnmounted, ref,
} from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import store from '@/store'
import {
  BPagination,
  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AtomSpinner from '@/components/Spinners/AtomSpinner.vue'
import CreateUserModal from '@/auth/views/admin/UserManagement/Modals/CreateUserModal.vue'
import DeleteUserModal from '@/auth/views/admin/UserManagement/Modals/DeleteUserModal.vue'

export default {
  name: 'UserManagement',
  components: {
    BTable,
    BPagination,
    AtomSpinner,
    CreateUserModal,
    DeleteUserModal,
  },
  directives: { Ripple },
  setup(props, context) {
    const loading = ref(false)
    const allUsers = computed(() => store.state.auth.allUsers)
    const fetchUsers = () => {
      loading.value = true
      store
        .dispatch('auth/getAllUsers')
        .finally(() => {
          loading.value = false
        })
    }
    fetchUsers()
    onUnmounted(() => { store.commit('auth/clearAllUsers') })

    const userTableFields = [
      {
        key: 'enabled',
        label: 'Status',
        sortable: true,
        thStyle: {
          width: '5rem',
        },
        tdClass: 'text-center',
      },
      // {
      //   key: 'avatar',
      //   sortable: false,
      //   label: '',
      //   title: 'Photo',
      //   thStyle: {
      //     width: '4rem',
      //   },
      // },
      { key: 'username', label: 'Username', sortable: true },
      { key: 'firstName', label: 'Name', sortable: true },
      {
        key: 'email',
        label: 'Email',
        tdClass: 'text-nowrap',
        sortable: true,
      },
      {
        key: 'requiredActions',
        label: 'User actions required',
        thClass: 'text-center',
        sortable: false,
      },
      {
        key: 'actions',
        label: 'Actions',
        thClass: 'text-center',
        tdClass: 'text-center',
        sortable: false,
      },
    ]
    const perPage = 20
    const pageOptions = [10, 20, 50, 100]
    const totalRows = computed(() => allUsers.value.length)
    const currentPage = 1
    const sortBy = 'username'
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const sortOptions = userTableFields
      .filter(f => f.sortable)
      .map(f => ({ text: f.label, value: f.key }))

    const resolveActionDescription = action => {
      const descriptions = {
        VERIFY_EMAIL: 'User must verify that they have a valid email account. An email will be sent to the user with a link they have to click. Once this workflow is successfully completed, they will be allowed to log in',
        UPDATE_PASSWORD: 'User must change their password',
        UPDATE_PROFILE: 'User needs to update their profile information',
        CONFIGURE_TOTP: 'User needs to configure their time-based one time password',
        TERMS_AND_CONDITIONS: 'User has not accepted the platform terms and conditions',
      }
      return descriptions[action]
    }

    const selectedUserId = ref('')
    const activateUser = userId => {
      coreService.userManagementApi
        .activateUser(userId)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Access Granted',
              text: `${response.firstName} ${response.lastName} can now access the Kompozition platform.
              Any previously shared resources have been reshared with ${response.firstName}.`,
              icon: 'UserCheckIcon',
              variant: 'success',
            },
          })
          fetchUsers()
        })
    }
    const deactivateUser = userId => {
      coreService.userManagementApi
        .deactivateUser(userId)
        .then(response => {
          context.root.$toast({
            component: ToastificationContent,
            props: {
              title: 'Access Revoked',
              text: `${response.firstName} ${response.lastName} no longer has access to the Kompozition platform.`,
              icon: 'UserMinusIcon',
              variant: 'success',
            },
          })
          fetchUsers()
        })
    }
    const openDeleteUserModal = userId => {
      selectedUserId.value = userId
      nextTick(() => context.root.$bvModal.show('delete-user-modal'))
    }

    return {
      loading,
      allUsers,
      fetchUsers,

      selectedUserId,
      activateUser,
      deactivateUser,
      openDeleteUserModal,

      userTableFields,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      sortOptions,
      resolveActionDescription,
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-link {
  color: rgb(87, 157, 255);
  :hover {
    text-decoration: underline !important;
    color: rgb(87, 157, 255);
  }
}
</style>
